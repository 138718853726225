import React, { useState, useEffect } from 'react';
import { AuthContext } from '../providers/Auth.js';
import { AuthCard } from '../components/Authentication.js';
import { ModalContext } from '../providers/Modal.js';
import { DefaultModal } from '../components/Modals.js';
import { MonthNames } from '../providers/DefaultObjects.js';
import { Colors } from '../theme/Colors.js';
import { OneColumnContent } from '../components/ContentAreas.js';
import { PageTitle } from '../components/Titles.js';
import { SummaryHeaderDashboard } from '../components/SummaryHeader.js';
import { LinkCard } from '../components/Cards.js';
import { NavButton } from '../components/Buttons.js';
import { ExpenseInputs, SavingInputs } from './finances/InputRules.js';
import { TransactionForm } from './finances/TransactionForm.js';
import { SetFormBody } from './finances/FormBodyHelper.js';
import { useDetailedSummary, } from './finances/SummaryHooks.js';
import { SummaryTable } from './finances/SummaryTables.js';
import { 
  useFinantialsBudget,
  useCategoryBudgets
} from './finances/BudgetHooks.js';

const Home = () => {
  const { user } = React.useContext(AuthContext);
  const { modal, setModal } = React.useContext(ModalContext);
  const today = new Date();
  const [referenceMonth, setReferenceMonth] = useState(today.getMonth());
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalSavings, setTotalSavings] = useState(0);
  
  const detailedExpenses = useDetailedSummary(
    'expenses',
    user,
    referenceMonth,
    today.getFullYear(),
    100
  );
  
  const detailedSavings = useDetailedSummary(
    'savings',
    user,
    referenceMonth,
    today.getFullYear(),
    100
  );
  
  const expensesBudget = useFinantialsBudget(
    'expenses', 
    user, 
    referenceMonth
  );
  
  const savingsBudget = useFinantialsBudget(
    'savings', 
    user, 
    referenceMonth
  );
    
  const categoriesBudget = useCategoryBudgets(user);

  const expensesBackground =
    expensesBudget < totalExpenses ? Colors.Red : Colors.Green;
  
  const savingsBackground =
    totalSavings < savingsBudget ? Colors.Red : Colors.Green;

  useEffect(() => {
    const newExpensesTotal =
      detailedExpenses.length > 0 ? detailedExpenses[0].total : 0;
    setTotalExpenses(newExpensesTotal);

    const newSavingsTotal =
      detailedSavings.length > 0 ? detailedSavings[0].total : 0;
    setTotalSavings(newSavingsTotal);
  }, [detailedExpenses, detailedSavings]);

  return (
    <>
      <OneColumnContent>
        <PageTitle>
          {MonthNames[referenceMonth]}
          <br></br>
          {today.getFullYear()}
        </PageTitle>
        <NavButton
          onClickBackwards={() =>
            setReferenceMonth((referenceMonth + 11) % 12)
          }
          onClickForward={() => 
            setReferenceMonth((referenceMonth + 1) % 12)
          }
        />
        <SummaryHeaderDashboard
          background={expensesBackground}
          heading="Disponível:"
          title={
            'R$ ' +
            (parseFloat(expensesBudget) - parseFloat(totalExpenses))
            .toFixed(2).replace('.', ',')
          }
          caption={
            'Total: R$' +
            parseFloat(totalExpenses)
            .toFixed(2).replace('.', ',')
          }
          onClick={() =>
            setModal({
              ...modal,
              title: 'Adicionar',
              display: null,
              body: (
                <TransactionForm
                  Path="expenses"
                  InputFields={
                    SetFormBody(ExpenseInputs,{
                      date: today.toISOString().slice(0, 10)
                    })}
                />
              ),
            })
          }
        />
        <SummaryHeaderDashboard
          background={savingsBackground}
          heading="Investido:"
          title={(
            (parseFloat(totalSavings) / parseFloat(savingsBudget)) * 100
          ).toFixed(2).replace('.', ',') + '%'}
          caption={
            'Total: R$' +
            parseFloat(totalSavings).toFixed(2).replace('.', ',')
          }
          onClick={() =>
            setModal({
              ...modal,
              title: 'Adicionar',
              display: null,
              body: (
                <TransactionForm
                  Path="savings"
                  InputFields={SetFormBody(SavingInputs, {
                    date: today.toISOString().slice(0, 10),
                  })}
                />
              ),
            })
          }
        />
        <SummaryTable 
          SummarizedData={detailedExpenses}
          CategoriesBudget={categoriesBudget}
          Budget={expensesBudget}
        />
        <div>
          <LinkCard emoji="💳" label="Gastos" link="/expenses"></LinkCard>
          <LinkCard emoji="🐖" label="Economias" link="/savings"></LinkCard>
          <LinkCard
            emoji="📆"
            label="Gastos Mensais"
            link="/recurring-expenses"
          ></LinkCard>
        </div>
        <div>
          <LinkCard emoji="🎯" label="Metas" link="/budgets"></LinkCard>
          <LinkCard emoji="⚙️" label="Perfil" link="/profile"></LinkCard>
          <AuthCard />
        </div>
      </OneColumnContent>
      <DefaultModal />
    </>
  );
};

export default Home;