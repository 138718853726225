import { useEffect, useState } from 'react';
import { MonthKeys } from '../../providers/DefaultObjects.js';
import { BudgetCategoriesInputs } from './InputRules.js';
import { 
  getDatabase, 
  ref, 
  onValue, 
} from 'firebase/database';


export const useFinantialsBudget = (
  type,
  user,
  referenceMonth
) => {
  const [budget, setBudget] = useState(0);
  const db = getDatabase();

  useEffect(() => {
    const today = new Date();
    const monthIndex = referenceMonth;
    const summary = ref(
      db, 
      `goals/${user.uid}/`+
      `${MonthKeys[monthIndex]}-`+
      `${today.getFullYear()}`
    );

    const handleSnapshot = (snapshot) => {
      const data = snapshot.val();
      if (data === null) {
        setBudget(0);
      }  
      else if (
        budget !== data[type] && 
        data[type]
      ) {setBudget(data[type])};
    };
    onValue(summary, handleSnapshot);
  }, [db, type, budget, user, referenceMonth]);

  return budget
}

export const useMonthlyBudgets = (user) => {
  const db = getDatabase();
  const date = new Date();
  const year = date.getFullYear();
  const [loaded, setLoaded] = useState(false);
  const [rows, setRows] = useState({
    january: {month: 'january', year: year, expenses: 0, savings: 0},
    february: {month: 'february', year: year, expenses: 0, savings: 0},
    march: {month: 'march', year: year, expenses: 0, savings: 0},
    april: {month: 'april', year: year, expenses: 0, savings: 0},
    may: {month: 'may', year: year, expenses: 0, savings: 0},
    june: {month: 'june', year: year, expenses: 0, savings: 0},
    july: {month: 'july', year: year, expenses: 0, savings: 0},
    august: {month: 'august', year: year, expenses: 0, savings: 0},
    september: {month: 'september', year: year, expenses: 0, savings: 0},
    october: {month: 'october', year: year, expenses: 0, savings: 0},
    november: {month: 'november', year: year, expenses: 0, savings: 0},
    december: {month: 'december', year: year, expenses: 0, savings: 0},
  });

  useEffect(() => {
    const databaseResults = ref(db, 'goals/' +  user.uid);
    const handleSnapshot = snapshot => {
      let data = snapshot.val();
      const monthValues = Object.values(data);
      const monthGoals = monthValues.filter(item => item.year === year);

      if (!loaded) {
        setLoaded(true);

        let newRows = { ...rows };
        monthGoals.map((item) => (
          newRows = {
            ...newRows,
            [item.month]: item,
          }
        ));
        setRows(newRows);
      };
    };
    onValue(databaseResults, handleSnapshot);
  }, [db, loaded, rows, year, user]);

  return rows;
};


export const useRecurringBudget = user => {
  const [recurringBudget, setRecurringBudget] = useState(0);
  const db = getDatabase();

  useEffect(() => {
    const summary = ref(
      db, 
      `recurringExpenses/${user.uid}`
    );

    const handleSnapshot = (snapshot) => {
      const data = snapshot.val();
      if (data === null) {
        setRecurringBudget(0);
      }  
      else if (
        recurringBudget !== data && 
        data
      ){
        const rows = Object.values(data);
        const valuesToSum = rows
          .filter(row => row.active === true);
        const initialValue = 0;
        const totalBudget = valuesToSum.reduce(
          (accumulator, values) => accumulator 
            + parseFloat(values['value']), initialValue
        );
        setRecurringBudget(totalBudget);
      }
    }
    onValue(summary, handleSnapshot);

  }, [db, recurringBudget, user]);
  return recurringBudget;
}

export const useCategoryBudgets = user => {
  const db = getDatabase();
  let categories = BudgetCategoriesInputs['category']['options'];
  categories.shift();
  let categoriesDict = {};
  for (var i in categories){
    categoriesDict[categories[i]] = 0;
  }
  const [rows, setRows] = useState(categoriesDict);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const summary = ref(db, `goalsCategories/${user.uid}`);
    const handleSnapshot = (snapshot) => {
      const data = snapshot.val();

      if (!loaded) {
        setLoaded(true);
        
        const categories = Object.keys(data)
        let newRows = { ...rows };
        categories.map((item) => (
          newRows = {
            ...newRows,
            [item]: data[item],
          }
        ));
        setRows(newRows);
      };
    };
    onValue(summary, handleSnapshot);
  }, [db, loaded, rows, user]);
  return rows;
};