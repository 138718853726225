export const BudgetCategoriesInputs = {
  category: {
    column: 'Categoria',
    name: 'category',
    type: 'options',
    options: [
      'Selecione uma categoria',
      'Alimentação',
      'Lanche',
      'Esporte',
      'Festa',
      'Lazer',
      'Educação',
      'Manutenção',
      'Mercado',
      'Minimercado',
      'Moradia',
      'Presente',
      'Saúde',
      'Transporte',
      'Vestuário',
      'Outros'
    ],
  },
  share: {
    column: 'Distribuição',
    name: 'share',
    type: 'relative',
  },
}

export const ExpenseInputs = {
  value: {
    column: 'Valor',
    name: 'value',
    type: 'price',
  },
  category: {
    column: 'Categoria',
    name: 'category',
    type: 'options',
    options: [
      'Selecione uma categoria',
      'Alimentação',
      'Lanche',
      'Esporte',
      'Festa',
      'Lazer',
      'Educação',
      'Manutenção',
      'Mercado',
      'Minimercado',
      'Moradia',
      'Presente',
      'Saúde',
      'Transporte',
      'Vestuário',
      'Outros'
    ],
  },
  date: {
    column: 'Data',
    name: 'date',
    type: 'date',
  },
  name: {
    column: 'Despesa',
    name: 'name',
    type: 'text',
  },
}

export const SavingInputs = {
  value: {
    column: 'Valor',
    name: 'value',
    type: 'price',
  },
  category: {
    column: 'Categoria',
    name: 'category',
    type: 'options',
    options: [
      'Selecione uma categoria',
      'Carro',
      'Casa',
      'Desenvolvimento pessoal',
      'Longo Prazo',
      'Reserva',
      'Viagem'
    ],
  },
  date: {
    column: 'Data',
    name: 'date',
    type: 'date',
  },
  fund: {
    column: 'Fundo',
    name: 'fund',
    type: 'text',
  },
}

export const BudgetInputs = {
  category: {
    column: 'Mês',
    name: 'category',
    type: 'options',
    inputPosition: 0,
    rowPosition: 0,
    options: [
      'Selecione uma categoria', 
      'Janeiro', 
      'Fevereiro', 
      'Março',
      'Abril', 
      'Maio', 
      'Junho', 
      'Julho',
      'Agosto', 
      'Setembro', 
      'Outubro', 
      'Novembro', 
      'Dezembro'
    ],
  },
  expense: {
    column: 'Orçamento',
    name: 'expense',
    type: 'price',
    inputPosition: 1,
    rowPosition: 1,
  },
  saving: {
    column: 'Economias',
    name: 'saving',
    type: 'price',
    inputPosition: 2,
    rowPosition: 2,
  },
}

export const RecurringExpensesInputs = {
  value: {
    column: 'Valor',
    name: 'value', 
    type: 'price',
  },
  category: {
    column: 'Categoria',
    name: 'category', 
    type: 'options', 
    options: [
      'Selecione uma categoria',
      'Alimentação',
      'Lanche',
      'Esporte',
      'Festa',
      'Lazer',
      'Educação',
      'Manutenção',
      'Mercado',
      'Minimercado',
      'Moradia',
      'Presente',
      'Saúde',
      'Transporte',
      'Vestuário',
      'Outros'
    ],
  },
  name: {
    column: 'Serviço',
    name: 'name', 
    type: 'text',
  },
  active: {
    column: '✅',
    name: 'active', 
    type: 'boolean',
  },

}